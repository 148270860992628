import React, { Fragment, useState } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import {
  PageWrapper,
  PageInner,
  PageTitle,
  PostFull,
  PostFullHeader,
  PostFullHeaderOverlay,
  PostFullContent,
  PostTags,
  PostFeedWrapper,
  AsideWidget,
  Aside,
  AuthorWidget,
  AuthorHeader,
  AuthorHeaderLogo,
  AuthorHeaderTitle,
  AuthorHeaderContent,
  AuthorHeaderInfo,
} from '../components/Elements';
import Tabs from '../components/Tabs';
import SubscribeForm from '../components/SubscribeForm';
import PostContentBlock from '../components/PostContentBlocks';
import ReadNextBlock from '../components/ReadNextBlock';
import ReadAlsoBlock from '../components/ReadAlsoBlock';

import { blogPostTypes } from '../types/propTypes';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';
import JsonLd from '../components/JsonLd';
import PostMetaBlock from '../components/PostMetaBlock';
import PostTagsBlock from '../components/PostTagsBlock';
import PostShareBlock from '../components/PostShareBlock';
import Img from 'gatsby-image';

import { colors } from '../consts/style';

const StickyAside = styled.div`
  position: sticky;
  top: 16px;
  margin: 0 0 64px;
`;

const PostHeader = styled.div`
  width: 100%;
  margin: 0 0 64px;

  h2 {
    font-weight: normal;
    font-size: 18px;
  }
`;

const PostNav = styled.nav`
  display: flex;
  flex-flow: column wrap;
  margin: 0 0 64px;
  padding: 0 0 56px;
  width: 100%;
  border-bottom: 1px solid ${colors.gray3};

  h3 {
    margin: 0 0 16px;
  }

  ol {
    margin: 0 0 0 16px;

    li {
      display: flex;

      a {
        margin: 1px 0 0;
      }
    }
  }

  a {
    color: ${colors.ramotionBlue};
    box-shadow: none;

    &:hover {
      color: ${colors.ramotionBlueHover};
      box-shadow: none;
    }
  }
`;

const AdditionalBlocks = styled.div`
  border-top: 1px solid ${colors.gray3};
  padding: 32px 0 0;
  margin: 32px 0 0;
`

const BlogPost = ({ data }) => {
  const {
    title,
    slug,
    meta,
    shortDescription,
    noIndex,
    schemaJson,
    seoMetaTags,
    postImage,
    mainContent,
    postTags,
    tagCloud,
    author,
    readNext,
    readAlso,
  } = data.project;


  const wordCount = mainContent.reduce((acc, cur) => {
    if (!cur.textNode) return acc + 0;
    return acc + cur.textNode.childMarkdownRemark.wordCount.words;
  }, 0);

  const hasNav = mainContent.reduce((acc, cur) => {
    if (!(cur.model.apiKey === 'anchored_post_heading')) return acc;
    return acc + 1;
  }, 0);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleFormSubmit = () => {
    setIsFormSubmitted(true);
  };

  const handleBackToForm = () => {
    setIsFormSubmitted(false);
  };

  return (
    <Fragment>
      <JsonLd>{schemaJson}</JsonLd>
      <SEO meta={seoMetaTags} noIndex={noIndex} />
      <PageWrapper>
        {postImage ? (
          <PostFullHeader background={`url(${postImage.url})`}>
            <PostFullHeaderOverlay />
            <PageInner>
              <Breadcrumbs title={title} />
              {postTags.length > 0 ? (
                <PostTags>
                  {postTags.map(tag => (
                    <Link to={`/tag/${tag.slug}/`} key={tag.id}>
                      {tag.name}
                    </Link>
                  ))}
                </PostTags>
              ) : null}

              <PageTitle>{title}</PageTitle>
              <p>{shortDescription}</p>

              <PostMetaBlock
                author={author}
                meta={meta}
                wordCount={wordCount}
              />
            </PageInner>
          </PostFullHeader>
        ) : null}

        <PageInner>
          <PostFeedWrapper>
            <PostFull>
              <PostFullContent>
                {!postImage ? (
                  <PostHeader>
                    <Breadcrumbs title={title} />
                    {postTags.length > 0 ? (
                      <PostTags>
                        {postTags.map(tag => (
                          <Link to={`/tag/${tag.slug}/`} key={tag.id}>
                            {tag.name}
                          </Link>
                        ))}
                      </PostTags>
                    ) : null}

                    <PageTitle>{title}</PageTitle>
                    <p>{shortDescription}</p>

                    <PostMetaBlock
                      author={author}
                      meta={meta}
                      wordCount={wordCount}
                    />
                  </PostHeader>
                ) : null}

                {hasNav > 0 ? (
                  <PostNav>
                    <h2>Table of Contents</h2>
                    <ol>
                      {mainContent.map(block =>
                        block ? (
                          <Fragment key={block.id}>
                            {block.model.apiKey === 'anchored_post_heading' && (
                              <li>
                                <a href={`#section-${block.titleId}`}>
                                  {block.title}
                                </a>
                              </li>
                            )}
                          </Fragment>
                        ) : null
                      )}
                    </ol>
                  </PostNav>
                ) : null}

                {mainContent.map(block =>
                  block ? (
                    <PostContentBlock block={block} key={block.id} />
                  ) : null
                )}

                <AdditionalBlocks>
                  {tagCloud.length > 0 ? (
                    <PostTagsBlock tagCloud={tagCloud} />
                  ) : null}

                  <PostShareBlock title={title} slug={slug} />
                </AdditionalBlocks>

                {readAlso.length > 0 ? (
                  <ReadAlsoBlock readAlso={readAlso} />
                ) : null}
              </PostFullContent>
            </PostFull>

            <Aside>
              <AuthorWidget>
                <AuthorHeader>
                  <AuthorHeaderTitle>
                    <AuthorHeaderLogo>
                        <Img fixed={author.logo.fixed} alt={author.name} />
                    </AuthorHeaderLogo>
                    <span>{author.name}</span>
                  </AuthorHeaderTitle>
                  <AuthorHeaderContent>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: author.descriptionNode.childMarkdownRemark.html,
                      }}
                    />
                    <AuthorHeaderInfo>
                      <p>
                        {author.socialLinks.map(item => (
                          <a key={item.id} href={item.link}>
                            {item.title}
                          </a>
                        ))}
                      </p>
                    </AuthorHeaderInfo>
                  </AuthorHeaderContent>
                </AuthorHeader>
              </AuthorWidget>

              <StickyAside>
                <SubscribeForm
                  formId={`contact-form-aside`}
                  slug={slug}
                  isFormSubmitted={isFormSubmitted}
                  onFormSubmit={handleFormSubmit}
                  onBackToForm={handleBackToForm}
                />

                {postTags.length > 0 ? (
                  <AsideWidget>
                    <Tabs postTags={postTags} />
                  </AsideWidget>
                ) : null}
              </StickyAside>
            </Aside>
          </PostFeedWrapper>
        </PageInner>
        <PageInner>
          <PostFull>
            <SubscribeForm
              formId={`contact-form-footer`}
              slug={slug}
              isFormSubmitted={isFormSubmitted}
              onFormSubmit={handleFormSubmit}
              onBackToForm={handleBackToForm}
            />
          </PostFull>
        </PageInner>
      </PageWrapper>

      {readNext.length > 0 ? <ReadNextBlock readNext={readNext} /> : null}
    </Fragment>
  );
};

export const projectQuery = graphql`
  query($slug: String!) {
    project: datoCmsBlogPost(slug: { eq: $slug }) {
      title
      slug
      shortDescription
      dateOfPublication(formatString: "MMM D, YYYY")
      meta {
        createdAt(formatString: "MMM D, YYYY")
        updatedAt(formatString: "MMM D, YYYY")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      schemaJson
      postImage {
        alt
        title
        url
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      mainContent {
        ... on DatoCmsAnchoredPostHeading {
          id
          model {
            apiKey
          }
          title
          titleId
        }
        ... on DatoCmsFeaturedSnippetBlock {
          id
          model {
            apiKey
          }
          title
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          model {
            apiKey
          }
          textNode {
            childMarkdownRemark {
              html
              wordCount {
                words
              }
            }
          }
        }
        ... on DatoCmsImageBlock {
          id
          model {
            apiKey
          }
          images {
            originalId
            title
            alt
            fluid {
              ...GatsbyDatoCmsFluid
              src
            }
          }
          imageLink
          imageLinkNofollow
          imageCaptionNode {
            childMarkdownRemark {
              html
            }
          }
          fullWidth
        }
        ... on DatoCmsVideoBlock {
          id
          model {
            apiKey
          }
          video {
            url
            provider
            providerUid
            title
          }
          internalVideo {
            url
            mimeType
          }
          videoCaptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTwitterBlock {
          id
          model {
            apiKey
          }
          embededTweetNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTableBlock {
          id
          model {
            apiKey
          }
          title
          tableNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsQuoteBlock {
          id
          model {
            apiKey
          }
          image {
            url
            alt
          }
          name
          company
          quoteNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsHighlightBlock {
          id
          model {
            apiKey
          }
          highlightTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsDownloadFileBlock {
          id
          model {
            apiKey
          }
          title
          file {
            id
            name
            link
            size
            format
          }
        }
        ... on DatoCmsCodeBlock {
          id
          model {
            apiKey
          }
          codeNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsProsconsBlock {
          id
          model {
            apiKey
          }
          title
          prosTitle
          consTitle
          consContentNode {
            childMarkdownRemark {
              html
            }
          }
          prosContentNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      postTags {
        id
        name
        slug
      }
      tagCloud {
        id
        name
        slug
      }
      author {
        name
        slug
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        logo {
          fixed(width: 64, height: 64) {
            ...GatsbyDatoCmsFixed
          }
        }
        socialLinks {
          id
          title
          link
        }
      }
      readNext {
        id
        dateOfPublication(formatString: "D MMM YYYY")
        meta {
          createdAt(formatString: "D MMM YYYY")
        }
        title
        slug
        shortDescription
        mainContent {
          ... on DatoCmsTextBlock {
            id
            textNode {
              childMarkdownRemark {
                wordCount {
                  words
                }
              }
            }
          }
        }
        postImage {
          alt
          title
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      readAlso {
        id
        dateOfPublication(formatString: "MMM D, YYYY")
        meta {
          createdAt(formatString: "MMM D, YYYY")
        }
        postTags {
          id
          name
          slug
        }
        title
        slug
        shortDescription
        mainContent {
          ... on DatoCmsTextBlock {
            id
            textNode {
              childMarkdownRemark {
                excerpt(pruneLength: 200)
                wordCount {
                  words
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogPost.propTypes = blogPostTypes;

export default BlogPost;
